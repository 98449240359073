.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100vh;
  
  @include respond(tab-land) {
    flex-direction: column-reverse !important;
    min-height: 100%;
  }
  
  @include respond(phone) {
    overflow-y: hidden;
  }
}

.about_container {
  width: 30%;
  margin-right: 10rem;

  @include respond(desk) {
    width: 40%;
  }

  @include respond(tab-land) {
    width: 80%;
    margin: 3.5rem auto 0 auto;
  }

  @include respond(phone) {
    width: 90%;
    text-align: center;
    margin-top: 3.5rem;
  }
}

.logo {
  @include respond(phone) {
    width: 250px;
  }
}

.logo_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include respond(desk) {
    width: 40%;
  }
  @include respond(phone) {
    margin-top: 3rem;
  }
}

.picker {
 position: absolute;
 font-size: 3rem;
 top: 4%;
 left: 4%;
 border-radius: 50%;
 padding: 1rem;
 cursor: pointer;
 box-shadow: 0 1rem 1rem rgba(29, 29, 29, 0.4);

 &:hover {
   opacity: .8;
 }
 @include respond(phone) {
  top: 2%;
  font-size: 1.5rem;
  }
}

.logo_image {
  background-image: url('https://i.imgur.com/xHDWyuv.png');
  width: 350px;
  height: 350px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;

  @include respond(tab-port) {
    width: 300px;
    height: 300px;
  }

  @include respond(phone) {
    width: 225px;
    height: 225px;
  }
}
