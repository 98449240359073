.main_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tech_container {
  display: flex;
  align-items: stretch;
  align-content: space-around;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto 10rem auto;

  @include respond(tab-port) {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    width: 100%;
  }
}

.technologies {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  &--icon:not(:last-child) {
    margin-right: 1rem;
  }

  &--icon {
    font-size: 7rem;

    @include respond(tab-land) {
      font-size: 4.5rem;
    }

    @include respond(phone) {
      font-size: 3rem;
    }
  }
}

.git_container {
  background-color: rgba(99, 98, 98, 0.09);
  box-shadow: 0 1rem 1rem rgba(0,0,0, .3);
  width: 30%;
  padding: 2rem;
  
  @include respond(desk) {
    width: 43%;
  }

  @include respond(tab-land) {
    width: 40%;
  }

  @include respond(tab-port) {
    width: 80%;
  }
}

.github__action {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;


  &--item {
    color: white;
    font-size: 1.5rem;
    font-weight: 400;
    font-family: 'Montserrat';
    overflow: hidden;
    margin-bottom: 0;
    padding: .4rem;
    text-decoration: none;
  }

  &:nth-child(even) {
    background-color: #2e3744;
  }

  &:nth-child(odd) {
    background-color: #3c4859;
  }
}

.github-link-alt {
  font-size: 1rem;
  margin-left: 2rem;
}

.technology {
  width: 30%;
  margin-right: 10rem;
  @include respond(desk) {
    width: 43%;
  }

  @include respond(tab-land) {
    width: 40%;
  }

  @include respond(tab-port) {
    margin-right: 0rem;
    margin-bottom: 3rem;
    width: 80%;
  }
}

.tech_heading {
 text-align: center;
 margin-bottom: 5rem;

 @include respond(phone) {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

  &--text{
    color: $primary-green;
    font-family: 'Montserrat';
    font-size: 5rem;
    letter-spacing: .2rem;
  }
}

.cursive_heading--projects {
  font-family: 'Pattaya';
  color: $primary-green;
  font-size: 7rem;
  @include respond(phone) {
      font-size: 4rem;
  }
}
