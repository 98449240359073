.contact, .success_email {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100vh;
  margin: 0 auto;
  justify-content: center;

  @include respond(tab-port) {
    width: 300px;
  }
}

.form_header {
  margin-bottom: 3rem;
}

.form {
  background-color: rgba(99, 98, 98, 0.09);
  box-shadow: 0 1rem 1rem rgba(0,0,0, .3);
  padding: 20px;
  
  &_text {
    margin-top: 3rem;
  }

  &_container {
    width: 30%;
    margin-right: 10rem;

    @include respond(tab-land) {
      width: 80%;
      margin: 0 auto;
    }

    @include respond(phone) {
      margin-bottom: 8rem;
    }
  }

  &__group:not(:last-child) {
    margin-bottom: 1.2rem;
    display: flex;
    flex-direction: column;
  }

  &__input {
    font-size: 1.5rem;
    padding: .3rem .8rem;
    border-radius: 2px;
    border: none;
    background-color: rgb(225, 225, 225);
    font-family: 'Montserrat';
    color: inherit;
    display: block;
    border-bottom: 3px solid transparent;
    transition: all .3s;

    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba(0,0,0, .1);
      border-bottom: 3px solid $success-green;
    }

    &:focus:invalid {
      border-bottom: 3px solid $primary-red;
    }

    &::-webkit-input-placeholder {
      color: rgb(46, 46, 46);
    }
  
  }

  &__label {
    font-family: 'Montserrat';
    font-size: 1.2rem;
    font-weight: 700;
    text-align: left;
    margin-top: .4rem;
    padding-left: .8rem;
    display: block;
    transition: all .3s;
    color: $primary-green;
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem)
  }
}
