.green {
  color: $primary-green;
}
.white {
  color: white;
}

.text {
  color: white;
  font-family: 'Montserrat';
  font-size: 1.8rem;
}

.primary_heading {
  color: $primary-green;
  font-family: 'Montserrat';
  font-size: 5rem;
  margin: 20px auto 5px auto;
  letter-spacing: .2rem;

  @include respond(phone) {
    font-size: 3rem;
  }
}

.secondary_heading {
  color: $primary-green;
  font-family: 'Montserrat';
  font-size: 3rem;
  margin-bottom: 1rem;
  letter-spacing: .2rem;

  @include respond(phone) {
    font-size: 1.5rem;
  }
}

.footer-text {
  color: white;
  font-family: 'Montserrat';
  margin-top: 1rem;
  font-size: 1.3rem;
}

.cursive_heading {
  font-family: 'Pattaya';
  color: $primary-green;
  font-size: 7rem;
  @include respond(phone) {
      font-size: 5rem;
  }
}
