.link_container {
  margin-top: 1.5rem;
  display: flex;

  &--icon {
    color: $primary-green;
    font-size: 3rem;
    cursor: pointer;
    margin: 0px 5px 0px 5px;

    &:hover{
      opacity: .7;
    }
  }
}

.button_container {
  margin-top: 1rem;

  @include respond(phone) {
    position: absolute;
    top: 2%;
    right: 4%;
    margin-top: .8rem;
  }

  &--button:not(:last-child) {
    margin-right: 1rem;

    @include respond(phone) {
      margin-right: .75rem !important;
    }
  }

  &--button {
    @include respond(phone) {
      font-size: .95rem !important;
    }
  }
}
