@mixin noshow {
  display :none; 
}

// Media Query Manager

@mixin respond($breakpoint) {
  
  @if $breakpoint == phone {
    @media (max-width: 650px) {@content};
  }

  @if $breakpoint == tab-port {
    @media (max-width: 900px ) { @content };
  }

  @if $breakpoint == tab-land {
    @media (max-width: 1200px) { @content };
  }

  @if $breakpoint == desk {
    @media (max-width: 1800px) { @content };
  }
}
