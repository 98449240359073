*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
    min-height: 100%;
}

body {
    box-sizing: border-box;
    background: $primary-grey;
}

a {
    text-decoration:none;
   }
