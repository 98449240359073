.project_main_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include respond(tab-land) {
    height:auto;
    margin: 8rem auto 15px auto;
  }
  
  @include respond(phone) {
    height:auto;
    margin: 8rem auto 15px auto;
  }
}

.project_container {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;

  @include respond(tab-port) {
    flex-direction: column;
  }
}

.project {
  width:35%;
  margin-bottom: 10rem;
  display: flex;
  background-color: rgba(99, 98, 98, 0.09);
  box-shadow: 0 1rem 1rem rgba(0,0,0, .4);
  border-radius: 4px;

  @include respond(desk) {
    width: 45%;
  }

  @include respond(tab-port) {
    width: 90%;
    margin: 0 auto;
  }
  @include respond(phone) {
    width: 85%;
  }

  &_heading {
    color: $primary-green;
    text-align: center;
    font-family: 'Montserrat';
    font-size: 4rem;
  }

  &_content{
    display: flex;
    align-items: center;
    padding: 20px;

    @include respond(tab-land) {
      justify-content: center;
      flex-direction: column;
      border: none !important;
    }

    &--info {
      padding: 15px;
      @include respond(tab-land) {
        padding-left: 0px;
        padding-right: 0px;
      }
  
    }

    &--links {
      margin-top: 2rem;
    }

    &--icon {
      font-size: 3rem;
      margin-right: 1.2rem;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
